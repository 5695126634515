<template>
  <Dialog
    :visible="open"
    dismissable-mask
    @update:visible="
      (value) => {
        if (!value) {
          close();
          route = null;
        }
      }
    "
    modal
    :pt="{
      header: { style: ' display:none' },
      content: { style: 'padding: 0 !important;border-radius: 10px ' },
    }"
  >
  <div class="flex h-full flex-col justify-between p-10" style="width:840px;padding-top:10px;">
  <div class="mb-5 flex items-center justify-end">
    <button
      class="bg-background-3 text-primary flex h-8 w-8 items-center justify-center rounded-full"
      @click="close"
    >
      <span class="font-bold">X</span>
    </button>
  </div>

  <div class="mb-5">
    <section-title style="float:left">Je fais une offre</section-title>
    <div class="bg-secondary hover:bg-ternary-light border-primary-lightest text-white hover:text-white active:bg-ternary-lightest active:text-ternary-lighter focus:ring-1 focus:text-ternary focus:bg-transparent focus:ring-ternary leading-none py-4 px-6 rounded-full flex select-none items-center justify-center text-[0.938rem] font-medium outline-none transition-all max-md:px-24" style="float:right;margin-right:10px">
      Nb de kilomètres aller – retour = {{ route.distance }} KMS
    </div>
  </div>

  <Message severity="error" v-if="error">
    {{ error }}
  </Message>

  <table style="width: 100%;">
    <thead>
      <tr>
        <th style="text-align: left;"></th>
        <th style="text-align: center;">Quantité</th>
        <th style="text-align: center;">HT</th>
        <th style="text-align: center;">TTC</th>

      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
       
          <FormField style="display:none"
            class="mb-4"
            name="pickup_fee"
            label="Tarif de prise en charge HT - (TVA 10%)"
            :errors="validationErrors"
          >
            <InputNumber
              v-model="routeData.distance"
           :value="route.distance"
          
            />
          </FormField>
          <FormField
            class="mb-4"
            name="pickup_fee"
            label="Tarif de prise en charge HT - (TVA 10%)"
            :errors="validationErrors"
          >
            <InputNumber
              v-model="routeData.pickup_fee"
              placeholder="Tarif de prise en charge HT - (TVA 10%)"
              :class="`pickfee w-full ${validationErrors.pickup_fee ? 'p-invalid' : ''}`"
              locale="fr-FR"
              :disabled="loading"
              currency="EUR"
              mode="currency"
              :max="99999"
            />
          </FormField>
        </td>
        <td style="text-align: center;">1</td>
        <td style="text-align: center;"> {{ pickupfee }} € </td>

        <td style="text-align: center;"> {{ pickupfeettc }} € </td>
      </tr>
      <tr>
        <td>
          <FormField
            class="mb-4"
            name="km_price"
            label="Prix au kilomètre HT - (TVA 10%)"
            :errors="validationErrors"
          >
            <InputNumber
              currency="EUR"
              locale="fr-FR"
              mode="currency"
              :max="9999999"
              v-model="routeData.km_price"
              placeholder="Prix au kilomètre HT - (TVA 10%)"
              :class="`w-full ${validationErrors.km_price ? 'p-invalid' : ''}`"
              :disabled="loading"
            />
          </FormField>
        </td>
        <td style="text-align: center;">{{ route.distance }}</td>
        <td style="text-align: center;"> {{ kmprice }} € </td>
        <td style="text-align: center;"> {{ kmpricettc }} € </td>
      </tr>
      <tr>
        <td>
          <FormField
            class="mb-4"
            name="comission_fee"
            label="Commission Funargos HT - (TVA 20%)"
          >
            <InputNumber
              v-model="routeData.comission_fee"
              placeholder="Commission Funargos HT - (TVA 20%)"
              :class="`pickfee w-full`"
              locale="fr-FR"
              :disabled="true"
              currency="EUR"
              mode="currency"
              :max="99999"
            />
          </FormField>
        </td>
        <td style="text-align: center;">1</td>
        <td style="text-align: center;"> {{ comissionfee }} € </td>
        <td style="text-align: center;">{{ comissionfeettc }} € </td>
      </tr>
      <tr>
        <td>
          <FormField
            class="mb-4"
            name="forfait_fee"
            label="Autres frais (péage, frais étapes…) HT - (TVA 20%)"
            :errors="validationErrors"
          >
            <InputNumber
              v-model="routeData.forfait_fee"
              placeholder="Autres frais (péage, frais étapes…) HT - (TVA 20%)"
              :class="`pickfee w-full ${validationErrors.forfait_fee ? 'p-invalid' : ''}`"
              locale="fr-FR"
              :disabled="loading"
              currency="EUR"
              mode="currency"
              :max="99999"
            />
          </FormField>
        </td>
        <td style="text-align: center;">1</td>
        <td style="text-align: center;"> {{ forfaitfee }} € </td>
        <td style="text-align: center;">{{ forfaitfeettc }} € </td>
      </tr>
      <tr>
        <td colspan="2">
          <FormField style="margin:0"
            class="mb-4"
            name="mon_fee"
            label="Mon offre pour ce transport comprenant la Commission Funargos :"
          >
            <InputNumber style="display:none"
              v-model="routeData.mon_fee"
              placeholder="Mon offre HT - (TVA 20%)"
              :class="`pickfee w-full`"
              locale="fr-FR"
              :disabled="true"
              currency="EUR"
              mode="currency"
              :max="99999"
            />
          </FormField>
        </td>
       
        <td style="text-align: center;"> {{ monwfee }} € </td>
        <td style="text-align: center;">{{ monwfeettc }} € </td>
      </tr>

      <tr>
        <td colspan="2">
          <FormField style="margin:0"
            class="mb-4"
            name="mon_fee"
            label="Mon offre pour ce transport, commission Funargos déduite :"
          >
            <InputNumber style="display:none"
              v-model="routeData.mon_fee2"
              placeholder="Mon offre HT - (TVA 20%)"
              :class="`pickfee w-full`"
              locale="fr-FR"
              :disabled="true"
              currency="EUR"
              mode="currency"
              :max="99999"
            />
          </FormField>
        </td>
       
        <td style="text-align: center;"> {{ monfee }} € </td>
        <td style="text-align: center;">{{ monfeettc }} € </td>
      </tr>
    </tbody>
  </table>

  <rounded-button
    class="mx-auto"
    design="3"
    type="submit"
    :loading="loading"
    size="lg"
    @click="() => reserve()"
  >ENVOVER</rounded-button>
</div>

  </Dialog>
</template>
<script setup>
import { onMounted, ref } from "vue";
import Checkbox from "primevue/checkbox";
import { errorHandler } from "../../composables/utils";

const checked = ref();
const route = ref(null);
const open = ref(false); 
const loading = ref(false);
const error = ref(null);
const reference = ref(null);
const heure = ref(null);
const dateetheure = ref(null); 

const validationErrors = ref({});
const tarifs = ref([]);
const { $api, $listen, $event, $toast, $unListen, $stripe } = useNuxtApp();




const routeData = ref({
 
  km_price: 0,
 distance: 0,
 
  pickup_fee: 0,
  comission_fee: 0,
  forfait_fee: 0,
  mon_fee: 0,


 
});


const close = () => {
  open.value = false;
  route.value = null;
  loading.value = false;
  error.value = null;
};
const pickupfeettc = ref((0).toFixed(2));
const pickupfee = ref((0).toFixed(2));

const kmpricettc = ref((0).toFixed(2));
const kmprice = ref((0).toFixed(2));

const comissionfeettc = ref((0).toFixed(2));
const comissionfee = ref((0).toFixed(2));

const forfaitfeettc = ref((0).toFixed(2));
const forfaitfee = ref((0).toFixed(2));


var kmpricewihouttax = ref((0).toFixed(2));

const monfeettc = ref((0).toFixed(2));
const monfee = ref((0).toFixed(2));

const monwfeettc = ref((0).toFixed(2));
const monwfee = ref((0).toFixed(2));

var kmTotal = ref((0).toFixed(2));
var pickupTotal = ref((0).toFixed(2));
var comissionHT = ref((0).toFixed(2));
var kmTotalwithtax = ref((0).toFixed(2));
var total = ref((0).toFixed(2));
var totalwithtax = ref((0).toFixed(2));


 

watch(routeData, (newData) => {

  // Update each TTC field whenever the corresponding value changes in routeData
  pickupfeettc.value = (newData.pickup_fee * 1.1).toFixed(2); // Adding 10% tax to pickup_fee
  pickupfee.value = (parseFloat(newData.pickup_fee)).toFixed(2); // Adding 10% tax to pickup_fee

  kmpricettc.value = ((newData.km_price * route.value.distance) * 1.1).toFixed(2);     // Adding 10% tax to km_price
  kmprice.value = (newData.km_price * route.value.distance).toFixed(2);     // Adding 10% tax to km_price
 
routeData.distance = route.value.distance;
 kmTotal = newData.km_price * (routeData.distance || 0); // km_price * distance

   pickupTotal = newData.pickup_fee; // pickup_fee
   kmpricewihouttax = newData.km_price;
     comissionHT = (parseFloat(kmTotal) + parseFloat(pickupTotal)) * 20 / 100;

     routeData.value.comission_fee = comissionHT;
   

 
 kmTotalwithtax = kmpricettc.value ; //* (routeData.distance || 0); 

  comissionfeettc.value = (routeData.value.comission_fee * 1.2).toFixed(2); // Adding 10% tax to comission_fee
  comissionfee.value = (routeData.value.comission_fee).toFixed(2); // Adding 10% tax to comission_fee

  forfaitfeettc.value = (parseFloat(newData.forfait_fee) * 1.2).toFixed(2);     // Adding 10% tax to forfait_fee
  forfaitfee.value = (parseFloat(newData.forfait_fee)).toFixed(2);     // Adding 10% tax to forfait_fee

  total = parseFloat(kmTotal) + parseFloat(pickupTotal) + parseFloat(newData.forfait_fee); //parseFloat(comissionHT) 

totalwithtax = parseFloat(kmTotalwithtax) + parseFloat(pickupfeettc.value)  + parseFloat(forfaitfeettc.value); //parseFloat(comissionfeettc.value)
var monf = parseFloat(kmTotal) + parseFloat(pickupTotal) + parseFloat(newData.forfait_fee) - parseFloat(comissionHT) ;

var monfttc = parseFloat(kmTotalwithtax) + parseFloat(pickupfeettc.value) + parseFloat(forfaitfeettc.value)  - parseFloat(comissionfeettc.value);

   routeData.value.mon_fee = total;
 
  monfeettc.value =  monfttc.toFixed(2);  //(total * 1.2).toFixed(2);             // Adding 10% tax to mon_fee
  monfee.value =  monf.toFixed(2);  //(total * 1.2).toFixed(2);             // Adding 10% tax to mon_fee

  monwfeettc.value =  parseFloat(totalwithtax).toFixed(2);  //(total * 1.2).toFixed(2);             // Adding 10% tax to mon_fee
  monwfee.value =  parseFloat(total).toFixed(2); 
console.log(monwfeettc.value,'mrshah');
}, { deep: true }); // Deep watch to observe changes in the object


const onBookingRequested = async (payload) => {

  route.value = payload;
  open.value = true;
  await fetchTarifs();
};

 async function fetchTarifs() {

  loading.value = true;
  try {
    tarifs.value = await $api.call("get", $api.route("me.tarifs.index"));
  
      console.log("wow");
    // If you want to populate specific form fields with fetched data:
    if (tarifs.value.length > 0) {
      // Assuming 'km_price' is part of the first tarif object
    
      routeData.value.pickup_fee = parseFloat(tarifs.value[0].price_en_charge).toFixed(2) || null;
    

      routeData.value.km_price = parseFloat(tarifs.value[0].price_km).toFixed(2) || null;
     
  //routeData.value.forfait_fee = tarifs.value[0].demarche_mb || null;


    }
 
  } catch (e) {
    error.value = e;
     console.error('Error details:', e.response || e);
    $toast.error(
      "Une erreur est survenue lors de la récupération des chauffeurs."
    );
  } finally {
    loading.value = false;
  }
}




onMounted(async () => {
 
  $listen("driverbooking.requested", onBookingRequested);
});

onBeforeUnmount(() => {
  
  $unListen("driverbooking.requested", onBookingRequested);


});

const validate = () => {
  validationErrors.value = {};

  if (!reference.value) {
    validationErrors.value.reference = [
      "Une référence de dossier est requise.",
    ];
  }

  return Object.keys(validationErrors.value).length === 0;
};

const reserve = (paymentIntentId = null) => {

 // if (!validate()) return;

  loading.value = true;

  if (!route.value) return;

const pricingapi = ref({
 
  total_ttc: totalwithtax.toFixed(2),
  total_ht: total.toFixed(2),
  funargos_commission: comissionfeettc,
  route_price_ttc: kmTotalwithtax,
  route_price_ht: kmTotal,
  km_price: kmpricettc,
  pickup_fee: pickupTotal,
  pickup_feewithouttax: pickupfeettc,
  km_pricewithouttax: kmpricewihouttax,
  funargos_commissionwithouttax: comissionHT,

  forfait: forfaitfeettc


  
});

  const itinerary = route.value.itinerary?.id ?? null;
console.log(routeData.value,'yasirshah');
  $api
    .call("post", $api.route("driverroutes.booking", { route: route.value.id }), {
      itinerary: itinerary,
      payment_intent_id: paymentIntentId,
      reference: reference.value,
      heure: heure.value, 
      pricing: pricingapi.value,
      dateetheure: dateetheure.value,

    })
    .then((reservation) => {
      $toast.success("Votre demande de réservation a été envoyée");
      $event("booking.created");
      close();
      loading.value = false;
    })
    .catch((err) => {
      if (err.response && err.response.status === 402) {
        const paymentIntentId = err.response.data.payment_intent_id;
        const clientSecret = err.response.data.client_secret;
        return $stripe.confirmCardPayment(clientSecret).then(async (result) => {
          if (result.error) {
            errorHandler(
              'Nous ne parvenons pas à authentifier votre moyen de paiement. Veuillez contacter votre banque pour plus de détails ou ajouter un autre moyen de paiement depuis la page "Mes informations".',
              error
            );
            loading.value = false;
            console.error(result.error);
          } else {
            await reserve(paymentIntentId);
            loading.value = false;
          }
        });
      } else {
        errorHandler(err, error);
        loading.value = false;
      }
    }); 
};
</script>
