<template>

  <div style="padding-left: 2.8rem; padding-right: 2.8rem; font-size:13px"
  class="bg-ternary group fixed right-0 top-[57%] z-20 hidden cursor-pointer flex-col items-center justify-center rounded-l-3xl px-10 py-6 text-center font-bold uppercase text-[1.125] text-white drop-shadow-lg xl:flex"
>
   <img
          style="width:4rem"
          src="/images/stickymap.png"
          alt="logo"
        />
       
        Publier<br />
        une demande de <br/>
        transport <br/>
        aller – retour
</div>
</template>
<script setup>
</script>
