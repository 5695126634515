<template>
  <Dialog
    dismissable-mask
    :visible="visible"
    @update:visible="$emit('update:visible', $event)"
    modal
    class="max-md:w-full"
    :pt="{
      header: { style: ' display:none' },
      content: { style: 'padding: 0 !important;border-radius: 10px ' },
      root: {
        style: 'padding: 20px',
      },
    }"
  >
    <div class="w-full max-w-5xl grid-cols-12 md:grid">
      <div class="col-span-6 mr-5 hidden md:block">
        <img
          class="block h-full w-full object-cover"
          src="/images/bg-pop-up.png"
          alt="map"
        />
      </div>
      <div class="h-full w-full px-5 pb-10 pt-10 md:col-span-6">
        <div class="flex h-full w-full flex-col justify-between">
          <div class="mb-5 flex w-full items-center justify-between">
            <section-title>Créer une alerte</section-title>
            <button
              class="bg-background-3 text-primary flex h-8 w-8 items-center justify-center rounded-full"
              @click="$emit('update:visible', false)"
            >
              <span class="font-bold">X</span>
            </button>
          </div>

          <div class="mb-5">
            Soyez informé quand un trajet <br />correspondant à vos critères est
            publié
          </div>

          <Message severity="error" v-if="error">
            {{ error }}
          </Message>
          <FormField
            class="mb-5 w-full"
            label-class="text-secondary"
            name="origin_city_id"
            label="Ville de départ"
            :errors="validationErrors"
          >
            <CitySelectField
              placeholder="Entrez la ville de départ"
              v-model="routeData.origin_city_id"
              :disabled="loading"
              :class="`w-full ${
                validationErrors?.origin_city_id ? 'p-invalid' : ''
              }`"
            ></CitySelectField>
          </FormField>
          <FormField
            class="mb-5 w-full"
            label-class="text-ternary"
            name="destination_city_id"
            label="Ville d'arrivée"
            :errors="validationErrors"
          >
            <CitySelectField
              placeholder="Entrez la ville d'arrivée"
              v-model="routeData.destination_city_id"
              :disabled="loading"
              :class="`w-full ${
                validationErrors?.destination_city_id ? 'p-invalid' : ''
              }`"
            ></CitySelectField>
          </FormField>
          <FormField
            class="mb-5 w-full"
            label="Date"
            name="date"
            :errors="validationErrors"
          >
            <div class="p-input-icon-right">
              <Calendar
                hour-format="24"
                :min-date="dayjs().toDate()"
                dateFormat="dd/mm/yy"
                v-model="date"
                :class="`w-full ${validationErrors.date ? 'p-invalid' : ''}`"
                :disabled="loading"
              />
            </div>
          </FormField>

          <Message
            severity="warn"
            v-if="!authStore.isAuthenticated"
            :closable="false"
          >
            Vous devez être connecté à votre compte pour créer une alerte
          </Message>
          <rounded-button
            v-else
            class="mx-auto"
            design="3"
            type="submit"
            size="lg"
            :loading="loading"
            @click="storeAlert"
            >Créer mon alerte
          </rounded-button>
        </div>
      </div>
    </div>
  </Dialog>
</template>
<script setup>
import CitySelectField from "../form/CitySelectField.vue";
import Card from "primevue/card";
import { ref } from "vue";

import { useAuthStore } from "~/store/auth";

// Define props

const { visible } = defineProps({
  visible: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["store", "update:visible"]);
const { $api, $event, $toast } = useNuxtApp();
const dayjs = useDayjs();
const routeData = ref({
  origin_city_id: null,
  destination_city_id: null,
  duration: null,
  type: null,
});

const loading = ref(false);

const duration = ref(null);
const date = ref(dayjs().toDate());
const validationErrors = ref({});
const error = ref(null);
const authStore = useAuthStore();

const storeAlert = () => {
  loading.value = true;
  error.value = null;
  validationErrors.value = {};

  const transportDate = dayjs(date.value).format("YYYY-MM-DD");

  let params = {
    origin_city_id: routeData.value.origin_city_id,
    destination_city_id: routeData.value.destination_city_id,
    date: transportDate,
    duration: duration.value,
  };

  return $api
    .call("post", $api.route("me.alerts.store"), params)
    .then((data) => {
      $toast.success("Alerte enregistré avec succès");
      emit("store");
      $event("alert-created", data);
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.data.message) {
          $toast.error(e.response.data.message);
          error.value = e.response.data.message;
        }

        if (e.response.status == 422) {
          if (e.response.data.errors) {
            $toast.error("Un ou plusieurs champs sont invalides");
            validationErrors.value = e.response.data.errors;
          }
        }
      } else {
      }
    })
    .finally(() => {
      loading.value = false;
    });
};
</script>
