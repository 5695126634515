<template>
  <section-wrapper class="bg-neutral text-[0.75rem] text-[#707070]">
    <section-container mobile-padding class="max-w-container">
      <div
        class="border-b border-[#414141] py-24 md:grid-cols-2 lg:grid lg:grid-cols-3"
      >
        <div
          class="order-3 flex flex-col items-end max-lg:col-span-2 max-lg:mb-10 max-lg:mt-10 max-lg:items-center max-lg:text-center lg:justify-between lg:text-right"
        >
          <img class="h-32 w-32" src="/images/logo-blanc.svg" alt="logo" />
          <div class="text-[0.75rem]">
            FUNARGOS<br />
            © Copyright 2023.<br />
            Conception & réalisation par FX-Comunik
          </div>
        </div>

        <div class="order-1 max-lg:mx-auto max-lg:mb-16 max-lg:w-44">
          <h2
            class="mb-8 text-[1.125rem] font-bold uppercase text-[#999999] max-lg:text-center"
          >
            Liens utiles
          </h2>
          <ul class="list-inside list-disc">
            <li
              class="mb-3 font-bold uppercase transition-all hover:text-[#A1A9C2]"
            >
              <NuxtLink to="/">Accueil</NuxtLink>
            </li>
            <li
              class="mb-3 font-bold uppercase transition-all hover:text-[#A1A9C2]"
            >
              <NuxtLink to="/nos-services">Nos services</NuxtLink>
            </li>
            <li
              class="mb-3 font-bold uppercase transition-all hover:text-[#A1A9C2]"
            >
              <NuxtLink to="/#nos-tarifs">Nos tarifs</NuxtLink>
            </li>
            <li
              class="mb-3 font-bold uppercase transition-all hover:text-[#A1A9C2]"
            >
              <NuxtLink to="/resultats">Rechercher un trajet</NuxtLink>
            </li>
            <li
              class="mb-3 font-bold uppercase transition-all hover:text-[#A1A9C2]"
            >
              <NuxtLink to="/dashboard">Espace personnel</NuxtLink>
            </li>
            <li
              class="mb-3 font-bold uppercase transition-all hover:text-[#A1A9C2]"
            >
              <NuxtLink to="/publier-un-trajet">Publier un trajet</NuxtLink>
            </li>
          </ul>
        </div>

        <div class="order-2 max-lg:mx-auto max-lg:mb-16 max-lg:w-44">
          <h2
            class="mb-8 text-[1.125rem] font-bold uppercase text-[#999999] max-lg:text-center"
          >
            Contact
          </h2>
          <div class="mb-5 flex text-[0.75rem]">
            <icon icon="pin" class="mr-3 mt-1 h-3 w-3"></icon>
            <div class="uppercase">
              <div class="font-bold">Adresse</div>
              <div>
                6 avenue Saint-Roch<br />
                38100 Grenoble
              </div>
            </div>
          </div>
          <div class="mb-5 flex text-[0.75rem]">
            <icon icon="telephone-fixe" class="mr-3 mt-1 h-3 w-3"></icon>
            <div class="uppercase">
              <div class="font-bold">Nous appeler</div>
              <a href="tel:+33458174065">04 58 17 40 65</a>
            </div>
          </div>

          <div class="flex text-[0.75rem]">
            <icon icon="telephone-fixe" class="mr-3 mt-1 h-3 w-3"></icon>
            <div class="uppercase">
              <div class="font-bold">Nous contacter</div>
              <a href="mailto:contact@funargos.fr">contact@funargos.fr</a>
            </div>
          </div>
        </div>
      </div>

      <div class="py-12 text-[0.75rem]">
        <NuxtLink
          class="font-medium transition-all hover:text-[#A1A9C2]"
          to="/mentions-legales"
          >Mentions légales</NuxtLink
        >
        -
        <NuxtLink
          class="font-medium transition-all hover:text-[#A1A9C2]"
          to="/cgu"
          >CGU</NuxtLink
        >
        -
        <NuxtLink
          class="font-medium transition-all hover:text-[#A1A9C2]"
          to="/politique-de-confidentialite"
          >Politique de confidentialité</NuxtLink
        >
        -
        <NuxtLink
          class="font-medium transition-all hover:text-[#A1A9C2]"
          to="/politique-cookie"
          >Politique cookies</NuxtLink
        >
      </div>
    </section-container>
  </section-wrapper>
</template>
<script setup lang="ts"></script>
