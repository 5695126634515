<template>
  <Dialog
    :visible="open"
    dismissable-mask
    @update:visible="
      (value) => {
        if (!value) {
          close();
          route = null;
        }
      }
    "
    modal
    :pt="{
      header: { style: ' display:none' },
      content: { style: 'padding: 0 !important;border-radius: 10px ' },
    }"
  >
    <div class="flex h-full flex-col justify-between p-10">
      <div class="mb-5 flex items-center justify-end">
        <button
          class="bg-background-3 text-primary flex h-8 w-8 items-center justify-center rounded-full"
          @click="close"
        >
          <span class="font-bold">X</span>
        </button>
      </div>

      <div class="mb-5 flex items-center justify-center">
        <section-title>Demande de réservation</section-title>
      </div>

      <available-route-card class="mb-5" hide-booking :route="route" @update-forfait-checked="handleForfaitChecked" />

      <Message severity="error" v-if="error">
        {{ error }}
      </Message>

      <div class="mb-5">
        En validant cette étape, l'auteur de l'annonce sera alerté de votre
        souhait de réservation. <br />
        Il lui appartient d'approuver votre réservation. <br /><br /><span
          class="font-bold"
        >
          Si votre demande est acceptée par le transporteur, le trajet sera
          automatiquement confirmé.</span
        ><br /><br />
        vous en serez notifié(e) par SMS et e-mail.<br />
        <br />
        <span class="font-bold"
          >Attention: Une demande d'autorisation de paiement sera envoyée à
          votre banque. <br />
       <!--   Cependant vous ne serez débité(e) qu'une fois le trajet terminé.<br /> -->
          Tant que votre réservation n'est pas acceptée, vous pouvez l'annuler à
          tout moment et vous ne serez pas débité(e).<br />
          Si votre réservation est refusée, vous ne serez pas débité(e).
        </span>
      </div>
      <FormField
        class="mb-2"
        name="reference"
        label="Référence dossier"
        :errors="validationErrors"
      >
        <div class="p-input-icon-right">
          <i class="pi pi-pencil" />
          <InputText
            v-model="reference"
            type="text"
            placeholder="Nom du défunt / référence dossier"
            :class="`w-full ${validationErrors.reference ? 'p-invalid' : ''}`"
            :disabled="loading"
          />
        </div>
      </FormField>

 <div class="mb-10 grid-cols-2 gap-x-10 md:grid" style="margin-top:10px">
            <FormField
              class="mb-2"
              name="name"
              label="Heure de mise à disposition du défunt"
              :errors="validationErrors"
            >
              <div class="p-input-icon-right">
                <i class="pi pi-pencil" />
                <InputText
                  v-model="heure"
                  name="heure"
                  type="time"
                  placeholder="Heure de mise à disposition du défunt"
                  :class="`w-full`"
                  :disabled="loading"
                />
              </div>
            </FormField>

                    <FormField
              class="mb-2"
              name="name"
              label="Date et heure limite d’arrivée"
              :errors="validationErrors"
            >
              <div class="p-input-icon-right">
                <i class="pi pi-pencil" />
                <InputText
                  v-model="dateetheure"
                  name="dateetheure"
                   type="datetime-local"
                  placeholder="Date et heure limite d’arrivée"
                  :class="`w-full`"
                  :disabled="loading"
                />
              </div>
            </FormField>

         </div>

      <div class="my-10 flex items-center">
        <checkbox
          required
          name="checked"
          inputId="check-route-confirm"
          :binary="true"
          class="mr-2"
          v-model="checked"
        />
        <label for="check-route-confirm" class="text-primary cursor-pointer"
          >Je confirme l'envoi de ma demande de réservation ?
        </label>
      </div>

      <rounded-button
        :disabled="!checked"
        class="mx-auto"
        design="3"
        type="submit"
        :loading="loading"
        size="lg"
        @click="() => reserve()"
        >RESERVER
      </rounded-button>
    </div>
  </Dialog>
</template>
<script setup>
import { onMounted, ref } from "vue";
import Checkbox from "primevue/checkbox";
import { errorHandler } from "../../composables/utils";

const checked = ref();
const route = ref(null);
const open = ref(false);
const loading = ref(false);
const error = ref(null);
const reference = ref(null);
const heure = ref(null);
const dateetheure = ref(null);
const forfaitChecked = ref(false);
const validationErrors = ref({});

const { $api, $listen, $event, $toast, $unListen, $stripe } = useNuxtApp();

const close = () => {
 reference.value = '';
 heure.value = '';
 dateetheure.value = '';
  open.value = false;
  route.value = null;
  loading.value = false;
  error.value = null;
};

const handleForfaitChecked = (value) => {
  forfaitChecked.value = value;
};
const onBookingRequested = (payload) => {
  route.value = payload;
  open.value = true;
};

onMounted(() => {

  $listen("booking.requested", onBookingRequested);
  
  
});

onBeforeUnmount(() => {
  $unListen("booking.requested", onBookingRequested);
});

const validate = () => {
  validationErrors.value = {};

  if (!reference.value) {
    validationErrors.value.reference = [
      "Une référence de dossier est requise.",
    ];
  }

  return Object.keys(validationErrors.value).length === 0;
};

const reserve = (paymentIntentId = null) => {
  if (!validate()) return;

  loading.value = true;

  if (!route.value) return;

  const itinerary = route.value.itinerary?.id ?? null;

  $api
    .call("post", $api.route("routes.booking", { route: route.value.id }), {
      itinerary: itinerary,
      payment_intent_id: paymentIntentId,
      reference: reference.value,
      heure: heure.value,
      dateetheure: dateetheure.value,
      forfaitChecked:forfaitChecked.value

    })
    .then((reservation) => {
      $toast.success("Votre demande de réservation a été envoyée");
      $event("booking.created");
      close();
      loading.value = false;
    })
    .catch((err) => {
      if (err.response && err.response.status === 402) {
        const paymentIntentId = err.response.data.payment_intent_id;
        const clientSecret = err.response.data.client_secret;
        return $stripe.confirmCardPayment(clientSecret).then(async (result) => {
          if (result.error) {
            errorHandler(
              'Nous ne parvenons pas à authentifier votre moyen de paiement. Veuillez contacter votre banque pour plus de détails ou ajouter un autre moyen de paiement depuis la page "Mes informations".',
              error
            );
            loading.value = false;
            console.error(result.error);
          } else {
            await reserve(paymentIntentId);
            loading.value = false;
          }
        });
      } else {
        errorHandler(err, error);
        loading.value = false;
      }
    });
};
</script>
